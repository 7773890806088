<template>
  <div>
    <div class="mt-1">
      <div
        v-if="Matter"
        :class="{
          'matter-card-max-width': isAnual && Matter.regime != isAnual.id,
        }"
        class="matterCard"
      >
        <div class="leftCol">
          <div class="leftLabel" :id="`numTabBtn-${Matter.id}`">
            {{ Matter.order }}
          </div>
          <div class="leftLabel" :id="`hoursTabBtn-${Matter.id}`">
            {{ semesterClassroonHours() }}
          </div>
          <!-- <div class="leftLabel" :id="`creditsTabBtn-${Matter.id}`">{{ this.Matter.week_autonomous_hours }}</div> -->
          <div
            class="leftLabel"
            v-if="
              Matter &&
              profileType &&
              institution &&
              institution.internal_use_id != 'ciisa_uss'
            "
            :id="`creditsTabBtn-${Matter.id}`"
          >
            <div v-if="Matter.use_matter_values && oldMatter">
              {{ oldMatter.credits }}
            </div>
            <div v-else>
              {{ Matter.credits }}
            </div>
          </div>
          <div
            class="leftLabel text-break"
            @click="(e) => e.target.classList.toggle('text-break')"
            v-if="
              (Matter && Matter.modalities) ||
              (Matter.use_matter_values && oldMatter && oldMatter.modalities)
            "
            :id="`modalities-badge-${Matter.id}`"
          >
            {{ modalitiesByOrder }}
            <template v-if="Matter.use_matter_values">
              <ModalitiesPopover
                v-if="oldMatter"
                :object="Matter"
                :content="matterModalities(oldMatter.modalities)"
                :triggers="'hover'"
                :placement="'left'"
              ></ModalitiesPopover>
            </template>
            <template v-else>
              <ModalitiesPopover
                :object="Matter"
                :content="matterModalities(Matter.modalities)"
                :triggers="'hover'"
                :placement="'left'"
              ></ModalitiesPopover>
            </template>
          </div>
          <b-popover
            :target="`numTabBtn-${Matter.id}`"
            placement="left"
            triggers="hover focus"
            :content="`N° de orden de la ${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )}`"
          ></b-popover>
          <template>
            <b-popover
              :target="`hoursTabBtn-${Matter.id}`"
              placement="left"
              triggers="hover focus"
              :content="
                $getVisibleNames(
                  'manual.total_pedagogical_hours',
                  true,
                  'Total de Horas Pedagógicas'
                )
              "
            ></b-popover>
          </template>

          <b-popover
            :target="`creditsTabBtn-${Matter.id}`"
            placement="left"
            triggers="hover focus"
            :content="`${$getVisibleNames(
              'manual.credits',
              true,
              'Créditos SCT'
            )}`"
          ></b-popover>
        </div>

        <div
          class="matterTitle"
          :style="{
            'background-color': matterFormationLine,
          }"
          :title="getMatterName(Matter.matter)"
        >
          <div class="matterLink">
            <b-badge
              variant="none"
              pill
              class="code-matter mb-1"
              v-b-tooltip.v-secondary.noninteractive="
                `${$getVisibleNames('manual.matter_code', false, 'Código')}`
              "
              >{{ Matter.code }}</b-badge
            >
            <div v-if="user && ![6, 7].includes(user.groups[0])">
              <b-link
                :id="`matter-link-${Matter.id}`"
                :to="`/matter/form/${Matter.id}/`"
                title=""
              >
                <div class="word-break">
                  {{ getMatterName(Matter.matter).toUpperCase() }}
                </div>
              </b-link>
            </div>
            <div v-if="user && [6, 7].includes(user.groups[0])">
              {{ getMatterName(Matter.matter).toUpperCase() }}
            </div>
            <b-tooltip
              v-if="user && ![6, 7].includes(user.groups[0])"
              :target="`matter-link-${Matter.id}`"
              variant="secondary"
              placement="right"
              :boundary-padding="100"
              noninteractive
            >
              <b-icon icon="box-arrow-up-right"></b-icon> Ir al
              {{
                $getVisibleNames(
                  "manual.matter_program",
                  false,
                  "Programa de Asignatura"
                )
              }}
            </b-tooltip>
          </div>
        </div>
        <div class="rightCol">
          <div
            class="preqLabel text-break"
            @click="(e) => e.target.classList.toggle('text-break')"
            :id="`prerequisite-label-${Matter.id}`"
          >
            <span v-if="prerequisitesIds.length > 0">
              {{ prerequisitesIds }}
            </span>
            <span v-if="prerequisitesIds.length == 0">-</span>
            <PrerequisitesPopover
              :object="Matter"
              :content="prerequisitesFiltered"
              :placement="'right'"
              :triggers="'hover focus'"
              :title="'Prerrequisitos'"
            >
            </PrerequisitesPopover>
            <!-- <b-popover
              :target="`prerequisite-label-${Matter.id}`"
              placement="right"
              triggers="hover focus"
              :content="prerequisitesFiltered"
            ></b-popover> -->
          </div>
          <template>
            <div class="compLabel" :id="`popover-button-variant-${Matter.id}`">
              <template v-if="competencesOrderedList.length > 0">
                <div
                  class="text-break"
                  @click="(e) => e.target.classList.toggle('text-break')"
                >
                  {{ competencesOrderedList }}
                </div>
              </template>
              <template v-else>
                <div>-</div>
              </template>
              <b-popover
                :target="`popover-button-variant-${Matter.id}`"
                triggers="hover"
                placement="righttop"
              >
                <template #title>
                  {{
                    $getVisibleNames("mesh.competence", true, "Competencias")
                  }}
                </template>
                <template v-if="competencesList.length">
                  <div
                    v-for="competence in competencesList"
                    :key="competence.id"
                  >
                    <div :key="competence.id" class="competence-container">
                      {{
                        competence.profile_cycle
                          ? competence.order +
                            "." +
                            competence.profile_cycle.order
                          : competence.order + "."
                      }}
                      <SentenceContainer
                        :Sentence="competence"
                        :boundedTextContainer="true"
                      ></SentenceContainer>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    Esta
                    {{
                      $getVisibleNames(
                        "mesh.egressprofilematter",
                        false,
                        "Asignatura"
                      )
                    }}
                    no tiene ninguna
                    {{
                      $getVisibleNames("mesh.competence", false, "Competencia")
                    }}
                    asociada.
                  </div>
                </template>
              </b-popover>
            </div>
          </template>
          <!-- <template v-for="competence in institutionCompetenceList">
            <div
              class="compLabel"
              v-bind:style="{ backgroundColor: competence.color }"
              :id="`tab-competence-${competence.id}-${Matter.id}`"
              :key="`tab-competence-${competence.id}-${Matter.id}`"
            >
              {{ competence.short_name }}
            </div>
            <b-popover
              :key="`popover-competence-${competence.id}-${Matter.id}`"
              :target="`tab-competence-${competence.id}-${Matter.id}`"
              placement="right"
              triggers="hover focus"
              :content="competence.name"
            ></b-popover>
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "MatterCard",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    PrerequisitesPopover: () =>
      import("@/components/reusable/PrerequisitesPopover"),
    ModalitiesPopover: () => import("@/components/reusable/ModalitiesPopover"),
  },
  props: {
    Matter: {
      type: Object,
      required: true,
    },
    EgressProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { default_bg_color: "#99c9d8" };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      matters: names.MATTERS,
      profile_competences: names.PROFILE_COMPETENCES,
      profile_types: names.PROFILE_TYPES,
      competences: names.COMPETENCES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      formation_lines: names.FORMATION_LINES,
      modalities: names.MODALITIES,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      egressProfileCycles: names.PROFILE_CYCLES,
      regimes: names.REGIMES,
      careers: names.CAREERS,
    }),
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.EgressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    career() {
      if (!this.EgressProfile) return null;
      return this.careers.find((x) => x.id == this.EgressProfile.career);
    },
    profileType() {
      if (!this.EgressProfile) return [];
      return this.profile_types.find(
        (x) => x.id == this.EgressProfile.egress_profile_type
      );
    },
    oldMatter() {
      if (!this.Matter) return null;
      return this.matters.find((x) => x.id == this.Matter.matter);
    },
    matterFormationLine() {
      if (!this.Matter) return this.default_bg_color;
      const formation_line = this.formation_lines.find(
        (x) =>
          x.id == this.Matter.formation_line &&
          x.faculties.includes(this.career.faculty)
      );
      if (formation_line) return formation_line.color;
      else return this.default_bg_color;
    },
    // institutionCompetenceList() {
    //   let list = [];
    //   this.Matter.institution_competences.forEach((element) => {
    //     let instance = this.institutionCompetences.find((x) => x.id == element);
    //     if (instance) {
    //       list.push(instance);
    //     }
    //   });
    //   return list;
    // },
    prerequisites() {
      let list = [];
      this.Matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        if (egress_profile_matter) {
          let instance = this.matters.find(
            (x) => x.id == egress_profile_matter.matter
          );
          if (instance) list.push(instance);
        }
      });
      return list;
    },
    competencesList() {
      if (!this.Matter) return [];
      let list = [];
      let competences = [];
      competences = this.profile_competences.filter(
        (x) =>
          x.egress_profile == this.Matter.egress_profile &&
          this.Matter.competences.includes(x.competence)
      );
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) =>
              x.id == competence.competence &&
              this.Matter.competences.includes(x.id)
          );
          let get_cycle = null;
          let profile_cycle_order = 0;
          this.egressProfileCycles.forEach((profile_cycle) => {
            if (
              profile_cycle.egress_profile == competence.egress_profile &&
              profile_cycle.cycle == competence.cycle
            ) {
              get_cycle = profile_cycle;
              profile_cycle_order = profile_cycle.order;
            }
          });
          if (get_competence) {
            list.push({
              id: competence.competence,
              order: competence.order,
              egress_profile: competence.egress_profile,
              cycle: competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              type: get_competence.type,
              verb: get_competence.verb,
              profile_cycle: get_cycle,
              profile_cycle_order: profile_cycle_order,
              full_sentence: get_competence.full_sentence,
              name: get_competence.name,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        return 0;
      });
    },
    prerequisitesIds() {
      let list = [];
      this.Matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        if (egress_profile_matter) {
          let instance = this.matters.find(
            (x) => x.id == egress_profile_matter.matter
          );
          if (instance) list.push(egress_profile_matter.order);
        }
      });
      list.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      return list.join(", ");
      // return this.Matter.prerequisites.join(", ");
    },
    modalitiesByOrder() {
      let list = [];
      if (this.Matter.use_matter_values && this.oldMatter) {
        this.matterModalities(this.oldMatter.modalities).forEach((element) => {
          if (element) list.push(element.order);
        });
      } else {
        this.matterModalities(this.Matter.modalities).forEach((element) => {
          if (element) list.push(element.order);
        });
      }
      list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
      return list.join(", ");
    },
    competencesOrderedList() {
      if (!this.competencesList) return [];
      let list = [];
      this.competencesList.forEach((competence) => {
        if (competence.profile_cycle_order == 0) list.push(competence.order);
        else list.push(competence.order + "." + competence.profile_cycle_order);
      });
      return list.join(", ");
    },
    prerequisitesFiltered() {
      let list = [];
      this.Matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        if (egress_profile_matter) {
          let instance = this.matters.find(
            (x) => x.id == egress_profile_matter.matter
          );

          if (instance) {
            instance["formation_line"] = egress_profile_matter.formation_line;
            instance["order"] = egress_profile_matter.order;
            list.push(instance);
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 0;
      });
      // if (list.length == 0) return "No tiene Prerequisitos";
      // return "Prerequisitos: " + list.join(", ");
    },
  },
  methods: {
    getMatterName(matter_id) {
      const matter = this.matters.find((x) => x.id == matter_id);
      return matter ? matter.name : "";
    },
    // competenceCapitals(competence) {
    //   let tmp = competence.name.split(" ");
    //   tmp = String(tmp[0][0] + tmp[tmp.length - 1][0]);
    //   tmp = tmp.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //   return tmp.toUpperCase();
    // },
    semesterClassroonHours() {
      if (!this.Matter || !this.profileType) return 0;
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      let matter_hours = [];
      if (this.Matter.use_matter_values) {
        matter_hours = this.matter_time_allocations.filter(
          (x) =>
            x.matter == this.Matter.matter &&
            pedagogical_hours_ids.includes(x.time_allocation) &&
            x.counts_towards_credits == true
        );
      } else {
        matter_hours = this.ep_matter_time_allocations.filter(
          (x) =>
            x.egress_profile_matter == this.Matter.id &&
            pedagogical_hours_ids.includes(x.time_allocation) &&
            x.counts_towards_credits == true
        );
      }
      matter_hours.forEach((hour) => {
        hours += parseFloat(hour.hours);
      });
      if (
        this.institution &&
        parseInt(this.institution.hour_decimal_places) === 0
      ) {
        return parseFloat(Math.round(hours));
      }
      return parseFloat(
        hours.toFixed(
          this.institution && parseInt(this.institution.hour_decimal_places) > 0
            ? parseInt(this.institution.hour_decimal_places)
            : 2
        )
      );
    },
    // creditSctChile() {
    //   return Math.round(
    //     (this.creditSemesterClassroomHours() + this.semesterAutonomousHours()) /
    //       parseFloat(this.school.hours_per_credit)
    //   );
    // },
    matterModalities(modalities_ids) {
      return this.modalities.filter((x) => modalities_ids.includes(x.id));
    },
  },
  created() {},
};
</script>

<style scoped>
.code-matter {
  border: 1px solid black;
  /* display: flex; */
  font-weight: bold;
  align-items: center;
  justify-content: center;
  font-size: 6.5pt;
  letter-spacing: 0.3px;
}
.matterTitle {
  padding: 7px 9px;
  font-size: 7.5pt;
  background-color: #f4f4f4;
  border: 1px solid #7c7c7c;
  margin-bottom: 0;
  border-radius: 2px;
  width: 100%;
}
.matterTitle .matterLink {
  background-color: rgb(241, 241, 241);
  min-height: 65px;
  padding: 0.25rem;
  border-radius: 12px;
  line-height: 1.2;
}
.matterLink > a {
  color: #2e2e2e !important;
}
.matterLink > a:hover {
  color: #007bff !important;
}
.matterCard {
  width: 100%;
  min-width: 160px;
  min-height: 80px;
  box-sizing: border-box;
  display: flex;
  font-size: var(--thirth-font-size);
  color: #2e2e2e;
}
.matter-card-max-width {
  max-width: 250px;
}
.matterLink {
  min-width: 6rem;
  word-wrap: break-word !important;
}
.leftCol {
  width: 22px;
  float: left;
  background: transparent;
  font-size: var(--thirth-font-size);
}
.rightCol {
  width: 28px;
  background: transparent;
  font-size: var(--thirth-font-size);
  margin-right: 0.3rem;
}
.leftLabel {
  color: #0c0c0c;
  background: transparent;
  min-height: 20px;
  height: auto;
  width: 22px;
  padding-top: 1px;
  font-size: 8pt;
  transition: 0.2s ease-out;
  border-top: 1px solid #575757;
  border-bottom: 1px solid #575757;
  border-left: 1px solid #575757;
}
.preqLabel {
  color: black;
  border: 1px solid #575757;
  height: auto;
  width: 28px;
  padding-left: 0.1em;
  margin-bottom: 0px;
  font-size: 8pt;
  transition: 0.2s ease-out;
  border-style: solid solid solid none;
  border-bottom: 0 !important;
  background: transparent;
}
.compLabel {
  color: black;
  border: 1px solid #575757;
  height: auto;
  width: 28px;
  margin-bottom: 0px;
  font-size: 8pt;
  transition: 0.2s ease-out;
  border-style: solid solid solid none;
  background: transparent;
}
.competence-container {
  display: flex;
}
.modalities-badge {
  font-size: 0.6rem;
  vertical-align: top !important;
  padding: 0.15rem 0.2rem;
  cursor: default !important;
  margin-left: 0.1rem;
}
@media print {
  .matterTitle .matterLink a {
    text-decoration: none !important;
    line-height: 1.5;
  }
  .code-matter {
    padding-top: 5px;
  }
  .matterCard {
    min-height: 100px;
  }
  .matterTitle .matterLink {
    min-height: 83px;
  }
  .leftCol {
    display: none !important;
  }
  .rightCol {
    display: none !important;
  }
  .leftLabel {
    display: none !important;
  }
  .rightCol {
    margin-right: 0rem !important;
  }
}
.text-break {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* Fuerza a romper las palabras largas en la siguiente línea */
/* .word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
} */
</style>